<template>
  <v-navigation-drawer
    v-model="model"
    :style="styles"
    disable-resize-watcher
    fixed
    right
    touchless
    width="400"
  >
    <template v-if="model">
      <v-row
        align="center"
        class="pa-3 mx-0"
      >
        Messenger
        <v-spacer />

        <v-btn
          class="ma-0"
          icon
          @click="setMsgDrawer(false)"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-row>

      <v-divider class="mx-3" />

      <v-list v-if="downloads.length">
        <v-list-item
          v-for="(download, i) in downloads"
          :key="i"
        >
          <v-list-item-avatar>
            <v-img />
          </v-list-item-avatar>

          <v-list-item-content>
            <v-list-item-title v-text="download.name" />
            <v-list-item-subtitle>Downloading</v-list-item-subtitle>
          </v-list-item-content>

          <v-list-item-action>
            <v-progress-circular
              indeterminate
              width="2"
              size="48"
            >
              <span>27%</span>
            </v-progress-circular>
          </v-list-item-action>
        </v-list-item>
      </v-list>

      <div
        v-else
        class="pa-3"
        style="height: 88%"
      >
        <span>There is nothing here</span><br><br>
        <span v-for="c,i in chats[msgAddr]" :key="i" :style="c.addr == address?``:`display: flex;justify-content: flex-end;`">
          {{ c.text }}<br>
        </span>
      </div>
      <v-text-field
        v-model="chatMsg"
        class="py-2"
        hide-details
        label=""
        name="chatMsg"
        append-icon="mdi-chat"
        outlined
        single-line
        @click:append="postMsg"
        @keydown.enter="postMsg"
      />
    </template>
  </v-navigation-drawer>
</template>

<script>
  // Utilities
  import {
    mapActions,
    mapGetters,
    mapMutations,
    mapState,
  } from 'vuex'

  export default {
    name: 'CoreMessages',
    data: () => {
      return {
        chatMsg: '',
      }
    },
    computed: {
      ...mapState('friends', ['chats']),
      ...mapGetters('games', ['parsedGames']),
      ...mapState('friends', [
        'msg', 'msgAddr',
      ]),
      ...mapState('wallet', [
        'address',
      ]),
      ...mapState('downloads', [
        'drawer',
        'downloading',
      ]),
      downloads () {
        return this.downloading.map(download => {
          return this.parsedGames.find(game => game.id === download)
        })
      },
      model: {
        get () {
          return this.msg
        },
        set (val) {
          this.setMsgDrawer(val)
        },
      },
      styles () {
        const styles = {}

        styles.left = (window.innerWidth - (350 + 400)) + 'px'
        console.log(styles)

        return styles
      },
    },
    watch: {
      chats () {
        console.log('update chat!')
      },
    },
    methods: {
      ...mapActions('chats', ['addChat']),
      ...mapActions('wallet', ['sendMsg']),
      ...mapMutations('downloads', ['setDrawer']),
      ...mapMutations('friends', ['setMsgDrawer']),
      postMsg () {
        var addr = this.msgAddr
        this.sendMsg(JSON.stringify({ msg: 'text_friend', addr, text: this.chatMsg }))
        console.log('domsg', this.chatMsg)
        // add to chat log..
        this.addChat({ recipient: addr, addr: this.address, text: this.chatMsg })
        this.chatMsg = ''
      },
    },
  }
</script>
